import { BrowserRouter, Route, Routes } from "react-router-dom"

import { ComingSoon } from "pages"
import { routes } from "utils"

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.index} element={<ComingSoon />} />
      </Routes>
    </BrowserRouter>
  )
}
