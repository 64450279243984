import { ReactComponent as Logo } from "assets/icons/logo.svg"
import "./ComingSoon.scss"

export const ComingSoon = (): JSX.Element => {
  return (
    <div className="coming-soon">
      <Logo className="coming-soon-logo" />
      Being Updated
    </div>
  )
}
